import React from "react";
import unescape from "lodash.unescape";
import Img from "gatsby-image";
import {gsap} from "gsap";

/**
 * 
 * @param {} title
 * Generates a page url string from the title of a post 
 */

var regex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
const genUrlFromTitle = title => {
  var resStr = title
    .replace(regex, '')
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/--/g, '-')
    .replace(/[^\w-]+/g, '');

  return resStr;
};
/  +/g
/**
 * 
 * @param {} 
 * Generates a case study url string from the title of a post 
 */

export const genCaseStudyUrl = (title, type, excerpt) => {
  if (type == 'page') {
    var resStr = excerpt;
  } else {
    var resStr = "/work/" + genUrlFromTitle(title);
  }
  return resStr;
};


/**
 * 
 * @param {} 
 * Generates a key based on a block name 
 */

export const genBlockKey = (blockStr, idx) => {
  var keyStr = blockStr.replace("_", "-");

  keyStr += "-" + idx;

  return keyStr;
};


/**
 * 
 * @param {} 
 * Searches for the index of a field in a block array
 */

export const searchFieldIndex = (fields, fieldName) => {
  var ctrIdx = 0;
  var foundIdx = 0;
  
  if(fields) {
    for (var field of fields) {
      if (field.block_type[0].acf_fc_layout === fieldName) {
        foundIdx = ctrIdx;
      }
      ctrIdx++;
    }
  }

  return foundIdx;
};


/**
 * The distinction between the following lies between how the practices data is retrieved from Wordpress. One of them
 * is for data retrieved from relationships, and another one from data retrieved from the case study query.
 * They can and should be merged into one, either by editing the custom API code on the wordpress side to send the same data, 
 * or by adding an if statement on the two. 
 */

/**
 * Renders the list of practices from category field.
 * @param {Array of Objects}    practices
 */
export const renderCaseStudyPracticesTwo = practices => (
  <p>
    {practices
      .filter(practiceObj => practiceObj.name !== "Featured")
      .map((practiceObj, index) => (
        <span key={index}>{unescape(practiceObj.name)}</span>
      ))}
  </p>
);

/**
 * @param {Array of Strings}     practices
 */
export const renderRelationshipCaseStudyPractices = practices => (
  <p>
    {practices
      // Do not render "Featured" category in the final list
      .filter(practiceString => practiceString !== "Featured")
      .map((practiceString, index) => (
        <span key={index}>{unescape(practiceString)}</span>
      ))}
  </p>
);

/**
 * Renders one or two authors for work piece
 * @param {Array of Strings}     authors
 */
export const renderAuthorsName = authors => {
  var authorsNameObj = [];
  var i = 0;
  for (var author of authors) {
    if (author.a_name) {
      if (i == 0) {
        authorsNameObj.push(
          <span key={`author-name--${author.a_name}`}>
            By: {author.a_name}{" "}
          </span>
        );
      } else {
        authorsNameObj.push(
          <span key={`author-name--${author.a_name}`}>and {author.a_name}</span>
        );
      }
    }
    i++;
  }

  return authorsNameObj;
};

/**
 * Renders thought piece categories
 * @param {Array of Strings}     categories
 */
export const renderThoughtCats = categories => {
  var thoughtCatsObj = [];
  var i = 0;
  for (var cat of categories) {
    if (cat.name) {
      if (i == 0) {
        thoughtCatsObj.push(
          <span key={`cat-name--${cat.name}`}>{cat.name}</span>
        );
      } else {
        thoughtCatsObj.push(
          <span key={`cat-name--${cat.name}`}>, {cat.name}</span>
        );
      }
    }
    i++;
  }

  return thoughtCatsObj;
}

/**
 * Renders thought piece date
 * @param {Array of Strings}     date
 */
export const renderPostDate = date => {
  let dateObj = [];

  var dateStr = JSON.parse(JSON.stringify(date));

  var dateFormatObj = new Date(dateStr);
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };

  dateObj.push(
    <span key={date}>{dateFormatObj.toLocaleDateString("en-US", options)}</span>
  );

  return dateObj;
};

/**
 * Renders thought piece authors
 * @param {Array of Strings}     authors
 */
export const renderAuthorsImg = authors => {
  var authorsImgObj = [];

  for (var author of authors) {
    if (author.a_headshot.localFile) {
      authorsImgObj.push(
        <div key={`author--${author.a_name}`} className="thoughts-archive-headshot thought-piece-callout__author">
          <Img className="thought-piece-callout__author-img thoughts-archive-headshot__img" alt={author.a_headshot.alt_text} fluid={author.a_headshot.localFile.childImageSharp.fluid}></Img>
        </div>
      );
    }
  }

  return authorsImgObj;
};


